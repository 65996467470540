<template>
  <EStoreFieldError v-slot="{ hasError }" :name="error" :class="margin">
    <label v-if="viewLabel">
      <span
        class="text-sm font-semibold text-e_store-light"
        :class="{ 'text-red-400': hasError }"
      >
        {{ label }}
      </span>
    </label>
    <div class="relative mt-2">
      <input
        class="e_store-text-field"
        :class="[{ error: hasError },inputClass]"
        v-bind="$attrs"
        :value="modelValue"
        :type="inputType"
        @input="updateValue"
      />
      <button
        v-if="inputType == 'password'"
        tabindex="-1"
        class="absolute -translate-y-1/2 cursor-pointer ltr:left-2 rtl:right-2 top-1/2 w-4 h-4 text-e_store-light"
        @click="showPass = !showPass"
      >
        <div v-if="type === 'password'">
          <v-icon v-if="showPass" icon="mdi mdi-eye" :size="16" class="block" />
          <v-icon v-else icon="mdi mdi-eye-off" :size="16" class="block" />
        </div>
      </button>
    </div>
  </EStoreFieldError>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label?: string
    margin?: string
    modelValue: string
    type: string
    viewLabel?: boolean
    inputClass?: string
    error?: string | undefined
  }>(),
  {
    label: '',
    margin: 'mb-3',
    type: 'text',
    viewLabel: true,
    inputClass: 'ltr:text-right rtl:text-left',
    error: undefined
  }
)

const emit = defineEmits(['update:modelValue'])

const showPass = ref(false)

const inputType = computed(() => {
  return showPass.value ? 'text' : props.type
})

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}
</script>
